<template>
  <div class="text-center">
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ucimg {
  background-color: #0f8575;
  color: white;
  padding-top: 30px;
}
</style>