<template>
<div :disabled="bgDisabled">
   
    <nav class="navbar navbar-expand-xl navbar-dark" role="navigation">
        <div class="container container-md container-sm">
            <router-link to="/" class="navbar-brand">
                <img src="@/assets/FDX900.png" alt="Financial Data Exchange logo" />
            </router-link>
            <button id="mobileMenuBtn" class="navbar-toggler" type="button" @click="toggleMobileMenu" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" :class="{ show: showMobileMenu }">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="https://financialdataexchange.org/FDX/About/FDX/About/About.aspx?hkey=dffb9a93-fc7d-4f65-840c-f2cfbe7fe8a6">About FDX</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" href="#">Certification</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://financialdataexchange.org/FDX/Membership/FDX/Membership/Benefits.aspx?hkey=3537db1b-f8eb-4c56-aeac-b492c89a2318">Membership</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://financialdataexchange.org/FDX/The%20Consortium/FDX/The-Consortium/Members.aspx?hkey=362ecd23-b752-48aa-b104-a99e916276c8">The Consortium</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://financialdataexchange.org/FDX/Newsroom/FDX/News/FDX-News.aspx?hkey=018fdd8f-d23e-407c-8732-810ca910f57c">Newsroom</a>
                    </li>
                    <li class="nav-item pt-1">
                        <button id="btn_sign_in" v-if="this.$store?.state?.Auth?.userRole === 'anonymous'" @click="doLogin" class="btn btn-success signin-btn " aria-label="Single Sign On">
                            Sign in
                        </button>
                        <div class="dropdown" v-else>
                            <button  v-if="JSON.parse(this.$store?.state?.Auth?.user)?.userLogo" style="margin-top: 4px; border: none !important; background-color: #ffffff !important;" id="logout-dropdown"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img :src="JSON.parse(this.$store?.state?.Auth?.user)?.userLogo" width="40" height="40" class="rounded-circle green-border" alt="user icon">
                                <img src="@/assets/Vector.png" class="arrow-img" alt="">
                            </button>
                            <button  v-else style="margin-top:4px;border: none !important; background-color: #ffffff !important;" aria-label="Logout" id="logout-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <em class="bi bi-person-circle icon-style" alt="user icon"></em>
                                <img src="@/assets/Vector.png" class="arrow-img" alt="">
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="logout-dropdown">
                                <button class="dropdown-item logout-btn" @click="doLogout" >Logout</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import {
    mapMutations,
    mapActions
} from "vuex";
export default {
    name: "Header",
    data() {
        return {
            showMobileMenu: false,
            bgDisabled: false,
        };
    },
   
    methods: {
        ...mapMutations(["setUserRole", "setUser", "setCallbackURL", "setPopup"]),
        ...mapActions([
            "updateUserInfo",
            "updateToken",
            "getCountries",
            "getEnumTypes",
            "getListingTags",
            "getOrganizationsList",
            "getOrganization",
            "ssoSignout"
        ]),
        popup(popup) {
            this.setPopup(popup);
        },
        doLogin() {
            this.setCallbackURL(this.$route.fullPath);
            this.$router.push("/login");
        },
        doLogout() {
            this.setCallbackURL(this.$route.fullPath);
            this.ssoSignout(true);
        },

        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
        disablebg(value) {
            this.bgDisabled = value;
        },
       
    },
};
</script>

<style scoped>
.logout-btn {
    font-size: 14px;
}

.dropdown-item:focus {
    color: #ffffff;
    background-color: #009382 !important;
}

.dropdown-item:hover {
    color: #ffffff;
    background-color: #009382 !important;
}

#mobileMenuBtn {
    color: #ffffff;
    background-color: #009382;
}

.signin-btn {
    background-color: #0f8575;
}

.signin-btn:hover {
    background: #0e7568;
    color: #ffffff !important;
    border: 1px solid #0e7568;
}

.nav-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #314959 !important;
    padding: 10px 0;
}

.nav-item,
.dropdown {
    padding: 10px 0;
}

#btn_sign_in {
    margin-top: 10px;
}

.active {
    color: #09645f !important;
}

.nav-item a.active {
    border-bottom: 3px solid #0f7c75;
}

.pt-1 {
    padding-top: 0px !important;
}

.usericon {
    margin-top: 7px;
}

@media (max-width: 1674px) {
    .nav-item a.active {
        width: fit-content;
    }
}

@media (max-width: 1200px) {
    .dropdown-menu.show {
        display: table-cell;
    }
}

.arrow-img {
    padding: 5px;
}

.green-border {
    border: 2px solid #0f8575;
}

.icon-style {
    font-size: 25px;
    color: #0f8575;
}


</style>
