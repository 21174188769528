<template>
  <div>
    <footer role="contentinfo">
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="footer-col">
              <h2>INFO</h2>
              <ul>
                <li><a href="https://financialdataexchange.org/FDX/Membership/Join-FDX.aspx">Join FDX</a></li>
                <li><a href="https://financialdataexchange.org/FDX/FDX/Membership/Join-FDX.aspx">Request API Access</a></li>
                <li><a href="https://financialdataexchange.org/FDX/Contact-Us.aspx">Contact</a></li>
                <li><a href="https://financialdataexchange.org/FDX/About/FAQs.aspx">FAQ</a></li>
              </ul>
            </div>
            <div class="footer-col">
              <h2>LEGAL</h2>
              <ul>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Privacy%20Policy-(11-13-2019).pdf" target="_blank" rel=noopener aria-label="Privacy link opens in new window">Privacy</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20API%20License%20Agreement-(11-13-2019).pdf" target="_blank" rel=noopener aria-label="FDX API link opens in new window">FDX API License Agreement</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Website%20-%20Terms%20of%20Use%20-%20TCs%20(11-13-2019).pdf" target="_blank" rel=noopener aria-label="Terms of Use link opens in new window">Terms of Use</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Legal%20Disclaimer.pdf" target="_blank" rel=noopener aria-label="FDX Legal Disclaimer link opens in new window">FDX Legal Disclaimer</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20-%20Parent%20-%20Subsidiary%20Membership%20Policy%20[Ratified].pdf" target="_blank" rel=noopener aria-label="FDX Parent-Subsidiary Membership Policy link opens in new window">FDX Parent-Subsidiary Membership Policy</a></li>
              </ul>
            </div>

            <div class="footer-col">
              <h2>SOCIAL</h2>
              <ul>
                <li>
                  <a href="https://twitter.com/fdxorg" target="_blank" rel=noopener aria-label="Twitter link opens in new window"><em class="bi bi-twitter icon-color"></em><span class="m-1">Twitter</span></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/financial-data-exchange" target="_blank" rel=noopener aria-label="Linkedin link opens in new window"><em class="bi bi-linkedin icon-color"></em><span class="m-1">LinkedIn</span></a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC4C7KRIoFCZxAF8iTI5-wbA" target="_blank" rel=noopener aria-label="Youtube link opens in new window"><em class="bi bi-youtube icon-color"></em><span class="m-1">YouTube</span></a>
                </li>
              </ul>
            </div>

            <div class="footer-col">
              <h2>DOWNLOAD</h2>
              <ul>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/FDX_Logos.zip" target="_blank" rel=noopener aria-label="Click here to download FDX logo">FDX Logos</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/FDX_Infographic.pdf" target="_blank" rel=noopener aria-label="Infographic link opens a pdf file  in new window">Infographic</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/10.3_FDX_WhitePaper_Final.pdf" target="_blank" rel=noopener aria-label="White Paper link opens a pdf file in new window">White Paper</a></li>
                <li><a href="https://financialdataexchange.org/common/Uploaded%20files/ABCs_of_APIs_FINAL_1.pdf" target="_blank" rel=noopener aria-label="ABC's of APIs link opens a pdf file in new window">ABC's of APIs</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-copyright">
        <div class="copyright">
          © 2020 Financial Data Exchange. All rights reserved.
        </div>
      </div>
    </footer>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "Footer",
};
</script>
<style scoped>
body {
  line-height: 1.5;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.copyright {
  max-width: 1170px;
  margin: auto;
  line-height: 16.41px;
  padding-bottom: 35px;
  padding-left: 15px;
  color: #bbc9d4;
  font-size: 14px;
  font-weight: 400;
}

.container {
  max-width: 1170px;
  margin: auto;
  text-align: Left;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footer {
  color: #fff;
  padding: 70px;
  font-size: 0.875rem;
  bottom: 0;
}
.footer-copyright {
  color: #fff;
  text-align: Left;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col h2 {
  font-size: 16px;
  color: #bbc9d4;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
  line-height: 24px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  background: transparent;
  color: #7b97a7;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
}
.footer-col ul li a:hover {
  color: #ffffff;
  color: #e6e6e6;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
}
.footer-col .social-links a:hover {
  background-color: #ffffff;
}

.icon-color {
  color: #1fa885;
  margin-right: 5px;
}
footer {
  background-color: #1d2b35;
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
  .copyright {
    padding-left: 80px;
  }
}
@media (max-width: 1300px) {
  .copyright {
    padding-left: 80px;
  }
}
</style>
