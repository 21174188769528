import api from "../../api/FdxCertificationPortalApi";
import router from '../../router/Router';
import Toast from "../../components/Notifications/Toast"

const vueState = {
    userRole: 'anonymous',
    user: null,
    showRREPopup: false,
    showREPopup: false,
    popup: false,
    popupimis: false,
    becomemember: false,
    refreshTokenExpired: false,
    authCode: null,
    token: null,
    tokenStartTime: null,
    callbackURL: null,
}

const getters = {
    getRefreshTokenStartTime(state) {
        return state.tokenStartTime;
    },
    getUserRole(state) {
        return state.userRole;
    },
    getPopup(state) {
        return state.popup
    },
    getPopupimis(state) {
        return state.popupimis
    },
    getAuthCode(state) {
        return state.authCode
    },
    getToken(state) {
        return state.token
    },
    getCallbackURL(state) {
        return state.callbackURL
    },
    getUser(state) {
        return state.user
    }
}

const mutations = {
    setUserRole(state, userRole) {
        state.userRole = userRole;
    },
    setTokenStatus(state, refreshTokenExpired) {
        state.refreshTokenExpired = refreshTokenExpired;
    },
    setTokenStartTime(state, tokenStartTime) {
        state.tokenStartTime = tokenStartTime;
    },
    setShowRREPopup(state, showRREPopup) {
        state.showRREPopup = showRREPopup;
    },
    setShowREPopup(state, showREPopup) {
        state.showREPopup = showREPopup;
    },
    setUser(state, user) {
        state.user = user;
    },
    setPopup(state, popup) {
        state.popup = popup;
    },
    setPopupimis(state, popupimis) {
        state.popupimis = popupimis;
    },
    setAuthCode(state, authCode) {
        state.authCode = authCode;
    },
    setToken(state, token) {
        state.token = token
    },
    setCallbackURL(state, callbackURL) {
        state.callbackURL = callbackURL
    }
}

const actions = {
    updateUserRole({ commit }, userRole) {
        commit('setUserRole', userRole);
    },
    updateUserInfo({ commit }, userInfo) {
        commit('setUser', userInfo);
    },
    updateToken({ commit }, token) {
        commit('setToken', token);
    },
    updateAuthcode({ commit }, authcode) {
        commit('setAuthCode', authcode);
    },
    updatePopup({ commit }, popup) {
        commit('setPopup', popup)
    },
    ssoAuthentication() {
        api.ssoAuthentication();
    },
    ssoSignout({ rootState }, req) {
        api.ssoSignout(rootState?.Auth?.token, req);
    },

    getAccessToken({ commit, dispatch }, token) {
            commit('setTokenStartTime', new Date().getTime());
            commit('setToken',JSON.stringify( token));
            commit('setTokenStatus', true);
            dispatch('getUserInfo');
    },

    async refreshToken({ commit, rootState }) {
        await api.refreshToken(rootState.Auth.token).then(response => {
            if (response?.status == 200 && response?.data) {
                commit('setTokenStartTime', new Date().getTime());
                commit('setTokenStatus', true);
                commit('setToken', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit('setTokenStatus', false);
            commit("setShowLoadingOnApiCall", false);
            if (error?.response) {
                if (error?.response?.status === 400) {
                    router.push("/login");
                }
            }
        })
    },

    getUserInfo({ commit, rootState }) {
        api.getUserInfo()
            .then(response => {
                if (response.status === 200 && response.data) {
                    const user = formatUserData(response.data);
                    const userRole = determineUserRole(response.data.memberType, user);

                    saveUserData(commit, user, userRole);

                    if (rootState.Auth.callbackURL) {
                        navigateBasedOnRole(userRole);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                Toast.showToast('Error occurred while getting user details from IMIS', 'E');
            });
    },

}

function formatUserData(data) {
    if (data.userLogo) {
        data.userLogo = "data:image/png;base64," + data.userLogo;
    }
    data.paymentStatus = false; // Default payment status
    return data;
}

function determineUserRole(memberType, user) {
    switch (memberType) {
        case "STAFI":
            return "admin";
        case "REGIL":
        case "REGIV":
            return "member";
        case "CONST":
        case "CONSU":
        case "CONTI":
            user.paymentStatus = true;
            return "member";
        case "NCONM":
        case "NCONP":
            return "non-member";
        default:
            user.paymentStatus = false;
            return "anonymous";
    }
}

function saveUserData(commit, user, userRole) {
    localStorage.setItem("user", JSON.stringify(user));
    commit('setUser', JSON.stringify(user));
    commit('setUserRole', userRole);
}

function navigateBasedOnRole(userRole) {
    router.push(userRole === 'admin' ? "/home" : "/");
}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}