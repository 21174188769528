import Auth from "./module/Auth";
import Company from "./module/Company";
import VuexPersistence from "vuex-persist";

import { createStore } from "vuex";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Company,
  },
  plugins: [vuexLocal.plugin],
});
