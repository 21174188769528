import Vue from 'vue';
import store from '../store';

import { createRouter, createWebHistory } from 'vue-router'

export const Role = {
    Admin: 'admin',
    Member: 'member',
    NonMember: 'non-member',
    Anonymous: 'anonymous'
}

const routeOptions = [
   
    { path: "/", name: "Home", meta: { authorize: [] }, breadcrumb: "Certification Home" },
    { path: "/home", name: "AdminHome", meta: { authorize: [Role.Admin] }, breadcrumb: "Certification Home" },
    { path: "/test-results/:id", name: "ViewResults", meta: { authorize: [Role.Admin] }, breadcrumb: "Test Results" },
    { path: "/createNewApplication", name: "CreateNewApplication", meta: { authorize: [Role.Member, Role.NonMember] }, breadcrumb: "Create New Application" },
    { path: "/paymentDetail", name: "PaymentDetail", meta: { authorize: [Role.Member, Role.NonMember] }, breadcrumb: "Payment Details" },
    { path: "/downloadDockerFile", name: "DownloadDockerFile", meta: { authorize: [Role.Member, Role.NonMember] }, breadcrumb: "Download Docker File" },
    { path: "/applicationDetail/:id", name: "ApplicationDetail", meta: { authorize: [Role.Admin, Role.Member, Role.NonMember] }, breadcrumb: "Application Details" },
    { path: "/login", name: "SSO", breadcrumb: "SSO Authentication" },
    { path: "/auth", name: "Auth", breadcrumb: "Authentication" },
    { path: "/logout", name: "Signout", breadcrumb: "Signout" }
];



const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/views/${route.name}.vue`),
    }
})


const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
  })
  const app = Vue.createApp({})
  app.use(router)

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    console.log("authorize", authorize);
    const currentUser = store?.getters?.getUserRole || Role.NonMember;
    if (authorize) {
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({ path: '/' });
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUser)) {
            // role not authorised so redirect to home page
            if (currentUser == 'admin') {
                return next({ path: '/home' });
            } else {
                return next({ path: '/' });
            }
        }
    } 

    next();
})

export default router;