<template>
  <div class="breadcrumb">
    <ul>
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
        <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.path">
          {{ breadcrumb.name }}
          <i class="bi bi-chevron-right"></i>
        </router-link>
        <template v-else>
          {{ breadcrumb.name }}
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
import VueRouter from "../router/Router";
export default {
  name: "Breadcrumb",
  data() {
    return {
      routes: [],
      breadcrumbs: [],
      rrr: VueRouter,
    };
  },
  watch: {
  $route(to) {
    if (to.path !== "/") {
      this.routes = to.path.split("/");
      this.routes[0] = "/";

      this.routes = this.routes.map((route) => {
        if (route !== "/") {
          route = "/" + route;
          return route;
        } else if (route === "/") {
          let user = this.$store.getters.getUserRole;
          if (user === 'admin') {
            return "/home";
          }
          return route;
        }
      });

      this.breadcrumbs = [];

      // Using `for-of` loop for this.routes
      for (const route of this.routes) {
        // Using `for-of` loop for VueRouter.options.routes
        for (const vueRoute of VueRouter.options.routes) {
          let path = vueRoute.path;
          if (path.includes(":")) {
            path = path.substring(0, path.indexOf(":") - 1);
          }
          if (route === path) {
            const breadcrumb = {
              path: vueRoute.path,
              name: vueRoute.breadcrumb,
            };
            this.breadcrumbs.push(breadcrumb);
            break; // Exit the inner loop once a match is found
          }
        }
      }
    } else {
      this.breadcrumbs = [];
    }
  },
},

};
</script>
<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #2c7a75;
  text-decoration: none;
}

li:last-child {
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #4e697b;
}

i {
  font-weight: bolder;
  font-size: 14px;
}

.breadcrumb {
  padding: 12px 0;
}</style>