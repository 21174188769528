<template>
  <div>
    <loading v-model:active="this.$store.state.Company.showLoadingOnApiCall" :loader="'bars'" :width=60 :height=80 :color="'#0f8575'" :can-cancel="false" :is-full-page="fullPage"></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "../../node_modules/vue-loading-overlay/dist/css/index.css";

export default {
  name: "overlay",
  components: {
    Loading,
  },
  data() {
    return {
      fullPage: true,
      loader: "",
    };
  },
  beforeUnmount() {
  },
  methods: {
    submit() {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: "#0f8575",
        loader: "bars",
        width: 60,
        height: 80,
        backgroundColor: "#ffffff",
        opacity: 0.5,
      });
    },
  },
};
</script>